import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './admin/modules/dashboard/dashboard.component';
import { CommonModule } from '@angular/common';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent },
  // ... other routes ...
];

// const routes: Routes = [
//   {
//     path: '',
//     component: AdminComponent,
//     children: [
//       { path: '', component: LoginComponent },
//       { path: 'dashboard', component: DashboardComponent },
//       { path: 'forgotpassword', component: ForgotpasswordComponent },
//       { path: 'adminmanagement', component: AdminManagementComponent },
//       { path: 'customer', component: CustomerComponent },
//       { path: 'participant', component: ParticipantComponent },
//       { path: 'reports', component: ReportsComponent },
//       { path: 'cms', component: CmsComponent },
//       { path: 'configuration', component: ConfigurationComponent },
//     ],
//   },
// ];
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forChild(routes),
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot(),
    NgxUiLoaderModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

export class I18nModule {
  constructor(translate: TranslateService) {
    translate.addLangs(['en']);
    translate.setDefaultLang('en');
  }
}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
