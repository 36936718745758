import { environment } from 'src/environments/environment';

export const constants = {
  NO_INTERNET_CONNECTION_MSG: 'There is no internet connection',
  WARNING_INTERNET_MSG: 'Please connect to stable internet',
  TIMEOUT_TIME: '360000000',
};

export const SIDEBAR_MENU = [
  { label: 'Dashboard', route: '/admin/dashboard', icon: 'dashboard-logo.png' },
  {
    label: 'Admin Management',
    route: '/admin/adminmanagement',
    icon: 'admin-managment-logo.png',
  },
  { label: 'Customer', route: '/admin/customer', icon: 'customer-logo.svg' },
  {
    label: 'Participant',
    route: '/admin/participant-list',
    icon: 'participant-logo.svg',
  },
  { label: 'Reports', route: '/admin/reports', icon: 'reports-logo.svg' },
  { label: 'CMS Management', route: '/admin/cms', icon: 'CMS-logo.svg' },
  {
    label: 'Configuration',
    route: '/admin/configuration',
    icon: 'config-logo.png',
  },
];

export const NAVIGATE_ROUTES = {
  LOGIN: environment.url + 'auth/login',
  LOGOUT: environment.url + 'auth/logout',
  //GET_ERM_TOKEN: environment.erm_token_url + 'default/token',
  //GET_PARTICIPANT_LIST: environment.erm_url + 'patient',
  //GET_ALL_LIST_CONFIGURATION: environment.erm_url + 'functionalstatus',
  //GET_ADL_SELF: environment.erm_url + 'adlself',
  GET_ERM_TOKEN: environment.url + 'openEMR/getToken',
  GET_PARTICIPANT_LIST: environment.url + 'openEMR/patientList',
  GET_ALL_LIST_CONFIGURATION: environment.url + 'openEMR/functionalstatus',
  GET_ADL_SELF: environment.url + 'openEMR/adlself',
  ADD_CONFIGURATION: environment.url + 'configuration/iadl',
  ADD_ADL: environment.url + 'configuration/adl',
  GET_CMS_PAGES: environment.url + 'cmsPages/listCmsPages',
  RESET_PASS_LINK: environment.url + 'auth/reset-password',
  ADD_CONTACT_US: environment.url + 'cmsPages/addContactUs',
  VERIFY_TOKEN: environment.url + 'auth/verify-forgot-password-token',
  RESET_PASS: environment.url + 'auth/set-new-password',
  GET_PROFILE: environment.url + 'auth/profile',
  GET_COUNTRY: environment.url + 'auth/countries',
  GET_STATES: environment.url + 'auth/states',
  GET_CITY: environment.url + 'auth/cities',
  GET_CUSTOMER_LIST: environment.url + 'customer/list',
  ADD_CUSTOMER: environment.url + 'customer',
};

export const CONFIGURATION = [
  {
    configLabel: 'Meal Preparation',
    configValue: 'meal_preparation',
    configDesc:
      'How meals are prepared (e.g., planning meals, assembling ingredients, cooking, setting out food and utensils',
    char: 'A',
    steps: [
      {
        label: 'Independent',
        desc: 'No help, setup, or supervision',
        input: ['Min Value'],
      },
      {
        label: 'Setup help only',
        desc: '',
        input: ['Min Value'],
      },
      {
        label: 'Supervision',
        desc: 'Oversight / Cuing',
        input: ['Min Value'],
      },
      {
        label: 'Limited Assistance',
        desc: 'Help on some occasions',
        input: ['Min Value'],
      },
      {
        label: 'Extensive Assistance',
        desc: 'Help throughout task, but performs 50% or more of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Maximal Assistance',
        desc: 'Help throughout task, but performs less than 50% of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Total Dependence',
        desc: 'Full performance by others during entire period',
        input: ['Min Value'],
      },
      {
        label: 'Activity did not occur',
        desc: 'During entire period [DO NOT USE THIS CODE IN SCORING CAPACITY]',
        input: ['Min Value'],
      },
    ],
  },

  {
    configLabel: 'Ordinary Housework',
    configValue: 'ordinary_housework',
    configDesc:
      'How ordinary work around the house is performed (e.g., doing dishes, dusting, making bed, tidying up, laundry)',
    char: 'B',
    steps: [
      {
        label: 'Independent',
        desc: 'No help, setup, or supervision',
        input: ['Min Value'],
      },
      {
        label: 'Setup help only',
        desc: '',
        input: ['Min Value'],
      },
      {
        label: 'Supervision',
        desc: 'Oversight / Cuing',
        input: ['Min Value'],
      },
      {
        label: 'Limited Assistance',
        desc: 'Help on some occasions',
        input: ['Min Value'],
      },
      {
        label: 'Extensive Assistance',
        desc: 'Help throughout task, but performs 50% or more of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Maximal Assistance',
        desc: 'Help throughout task, but performs less than 50% of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Total Dependence',
        desc: 'Full performance by others during entire period',
        input: ['Min Value'],
      },
      {
        label: 'Activity did not occur',
        desc: 'During entire period [DO NOT USE THIS CODE IN SCORING CAPACITY]',
        input: ['Min Value'],
      },
    ],
  },

  {
    configLabel: 'Managing Finances',
    configValue: 'managing_finances',
    configDesc:
      'How bills are paid, checkbook is balanced, household expenses are budgeted, credit card account is monitored',
    char: 'C',
    steps: [
      {
        label: 'Independent',
        desc: 'No help, setup, or supervision',
        input: ['Min Value'],
      },
      {
        label: 'Setup help only',
        desc: '',
        input: ['Min Value'],
      },
      {
        label: 'Supervision',
        desc: 'Oversight / Cuing',
        input: ['Min Value'],
      },
      {
        label: 'Limited Assistance',
        desc: 'Help on some occasions',
        input: ['Min Value'],
      },
      {
        label: 'Extensive Assistance',
        desc: 'Help throughout task, but performs 50% or more of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Maximal Assistance',
        desc: 'Help throughout task, but performs less than 50% of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Total Dependence',
        desc: 'Full performance by others during entire period',
        input: ['Min Value'],
      },
      {
        label: 'Activity did not occur',
        desc: 'During entire period [DO NOT USE THIS CODE IN SCORING CAPACITY]',
        input: ['Min Value'],
      },
    ],
  },

  {
    configLabel: 'Managing Medications',
    configValue: 'managing_medications',
    configDesc:
      'How medications are managed (e.g., remembering to take medicines, opening bottles, taking correct drug dosages, giving injections,applying ointments)',
    char: 'D',
    steps: [
      {
        label: 'Independent',
        desc: 'No help, setup, or supervision',
        input: ['Min Value'],
      },
      {
        label: 'Setup help only',
        desc: '',
        input: ['Min Value'],
      },
      {
        label: 'Supervision',
        desc: 'Oversight / Cuing',
        input: ['Min Value'],
      },
      {
        label: 'Limited Assistance',
        desc: 'Help on some occasions',
        input: ['Min Value'],
      },
      {
        label: 'Extensive Assistance',
        desc: 'Help throughout task, but performs 50% or more of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Maximal Assistance',
        desc: 'Help throughout task, but performs less than 50% of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Total Dependence',
        desc: 'Full performance by others during entire period',
        input: ['Min Value'],
      },
      {
        label: 'Activity did not occur',
        desc: 'During entire period [DO NOT USE THIS CODE IN SCORING CAPACITY]',
        input: ['Min Value'],
      },
    ],
  },

  {
    configLabel: 'Phone Use',
    configValue: 'phone_use',
    configDesc:
      'How telephone calls are made or received (with assistive devices such as large numbers on telephone, amplification as needed)',
    char: 'E',
    steps: [
      {
        label: 'Independent',
        desc: 'No help, setup, or supervision',
        input: ['Min Value'],
      },
      {
        label: 'Setup help only',
        desc: '',
        input: ['Min Value'],
      },
      {
        label: 'Supervision',
        desc: 'Oversight / Cuing',
        input: ['Min Value'],
      },
      {
        label: 'Limited Assistance',
        desc: 'Help on some occasions',
        input: ['Min Value'],
      },
      {
        label: 'Extensive Assistance',
        desc: 'Help throughout task, but performs 50% or more of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Maximal Assistance',
        desc: 'Help throughout task, but performs less than 50% of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Total Dependence',
        desc: 'Full performance by others during entire period',
        input: ['Min Value'],
      },
      {
        label: 'Activity did not occur',
        desc: 'During entire period [DO NOT USE THIS CODE IN SCORING CAPACITY]',
        input: ['Min Value'],
      },
    ],
  },

  {
    configLabel: 'Stairs',
    configValue: 'stairs',
    configDesc: 'How full flight of stairs is managed (12–14 stairs)',
    char: 'F',
    steps: [
      {
        label: 'Independent',
        desc: 'No help, setup, or supervision',
        input: ['Min Value'],
      },
      {
        label: 'Setup help only',
        desc: '',
        input: ['Min Value'],
      },
      {
        label: 'Supervision',
        desc: 'Oversight / Cuing',
        input: ['Min Value'],
      },
      {
        label: 'Limited Assistance',
        desc: 'Help on some occasions',
        input: ['Min Value'],
      },
      {
        label: 'Extensive Assistance',
        desc: 'Help throughout task, but performs 50% or more of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Maximal Assistance',
        desc: 'Help throughout task, but performs less than 50% of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Total Dependence',
        desc: 'Full performance by others during entire period',
        input: ['Min Value'],
      },
      {
        label: 'Activity did not occur',
        desc: 'During entire period [DO NOT USE THIS CODE IN SCORING CAPACITY]',
        input: ['Min Value'],
      },
    ],
  },

  {
    configLabel: 'Shopping',
    configValue: 'shopping',
    configDesc:
      'How shopping is performed for food and household items (e.g., selecting items, paying money)—EXCLUDE TRANSPORTATION',
    char: 'G',
    steps: [
      {
        label: 'Independent',
        desc: 'No help, setup, or supervision',
        input: ['Min Value'],
      },
      {
        label: 'Setup help only',
        desc: '',
        input: ['Min Value'],
      },
      {
        label: 'Supervision',
        desc: 'Oversight / Cuing',
        input: ['Min Value'],
      },
      {
        label: 'Limited Assistance',
        desc: 'Help on some occasions',
        input: ['Min Value'],
      },
      {
        label: 'Extensive Assistance',
        desc: 'Help throughout task, but performs 50% or more of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Maximal Assistance',
        desc: 'Help throughout task, but performs less than 50% of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Total Dependence',
        desc: 'Full performance by others during entire period',
        input: ['Min Value'],
      },
      {
        label: 'Activity did not occur',
        desc: 'During entire period [DO NOT USE THIS CODE IN SCORING CAPACITY]',
        input: ['Min Value'],
      },
    ],
  },

  {
    configLabel: 'Transportation',
    configValue: 'transportation',
    configDesc:
      'How travels by public transportation (navigating system, paying fare) or driving self (including getting out of house, into and out of vehicles)',
    char: 'H',
    steps: [
      {
        label: 'Independent',
        desc: 'No help, setup, or supervision',
        input: ['Min Value'],
      },
      {
        label: 'Setup help only',
        desc: '',
        input: ['Min Value'],
      },
      {
        label: 'Supervision',
        desc: 'Oversight / Cuing',
        input: ['Min Value'],
      },
      {
        label: 'Limited Assistance',
        desc: 'Help on some occasions',
        input: ['Min Value'],
      },
      {
        label: 'Extensive Assistance',
        desc: 'Help throughout task, but performs 50% or more of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Maximal Assistance',
        desc: 'Help throughout task, but performs less than 50% of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Total Dependence',
        desc: 'Full performance by others during entire period',
        input: ['Min Value'],
      },
      {
        label: 'Activity did not occur',
        desc: 'During entire period [DO NOT USE THIS CODE IN SCORING CAPACITY]',
        input: ['Min Value'],
      },
    ],
  },
];

export const ERM_TOKEN_INFO = {
  grant_type: 'password',
  client_id: 'BN9-aDG8wC_yU2zs1lHbQSp48yItNSweWEUdstxjTHQ',
  scope:
    'openid offline_access api:oemr api:fhir api:port patient/Patient.read patient/Procedure.read system/Patient.read system/AllergyIntolerance.read system/CarePlan.read system/CareTeam.read system/Condition.read system/Coverage.read system/Device.read system/DiagnosticReport.read system/DocumentReference.read system/Encounter.read system/Goal.read system/Group.read system/Immunization.read system/Location.read system/Medication.read system/MedicationRequest.read system/Observation.read system/Organization.read system/Person.read system/Practitioner.read system/PractitionerRole.read system/Procedure.read system/Provenance.read',
  user_role: 'users',
  username: 'admin',
  password: 'Admin@12345',
};

export const CONFIGURATION_ADL = [
  {
    configLabel: 'Bathing',
    configValue: 'bathing',
    configDesc:
      'How meals are prepared (e.g., planning meals, assembling ingredients, cooking, setting out food and utensils',
    char: 'A',
    steps: [
      {
        label: 'Independent',
        desc: 'No help, setup, or supervision',
        input: ['Min Value'],
      },
      {
        label: 'Setup help only',
        desc: '',
        input: ['Min Value'],
      },
      {
        label: 'Supervision',
        desc: 'Oversight / Cuing',
        input: ['Min Value'],
      },
      {
        label: 'Limited Assistance',
        desc: 'Help on some occasions',
        input: ['Min Value'],
      },
      {
        label: 'Extensive Assistance',
        desc: 'Help throughout task, but performs 50% or more of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Maximal Assistance',
        desc: 'Help throughout task, but performs less than 50% of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Total Dependence',
        desc: 'Full performance by others during entire period',
        input: ['Min Value'],
      },
      {
        label: 'Activity did not occur',
        desc: 'During entire period [DO NOT USE THIS CODE IN SCORING CAPACITY]',
        input: ['Min Value'],
      },
    ],
  },

  {
    configLabel: 'Personal hygiene',
    configValue: 'personal_hygiene',
    configDesc:
      'How ordinary work around the house is performed (e.g., doing dishes, dusting, making bed, tidying up, laundry)',
    char: 'B',
    steps: [
      {
        label: 'Independent',
        desc: 'No help, setup, or supervision',
        input: ['Min Value'],
      },
      {
        label: 'Setup help only',
        desc: '',
        input: ['Min Value'],
      },
      {
        label: 'Supervision',
        desc: 'Oversight / Cuing',
        input: ['Min Value'],
      },
      {
        label: 'Limited Assistance',
        desc: 'Help on some occasions',
        input: ['Min Value'],
      },
      {
        label: 'Extensive Assistance',
        desc: 'Help throughout task, but performs 50% or more of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Maximal Assistance',
        desc: 'Help throughout task, but performs less than 50% of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Total Dependence',
        desc: 'Full performance by others during entire period',
        input: ['Min Value'],
      },
      {
        label: 'Activity did not occur',
        desc: 'During entire period [DO NOT USE THIS CODE IN SCORING CAPACITY]',
        input: ['Min Value'],
      },
    ],
  },

  {
    configLabel: 'Dressing upper body',
    configValue: 'upper_body',
    configDesc:
      'How bills are paid, checkbook is balanced, household expenses are budgeted, credit card account is monitored',
    char: 'C',
    steps: [
      {
        label: 'Independent',
        desc: 'No help, setup, or supervision',
        input: ['Min Value'],
      },
      {
        label: 'Setup help only',
        desc: '',
        input: ['Min Value'],
      },
      {
        label: 'Supervision',
        desc: 'Oversight / Cuing',
        input: ['Min Value'],
      },
      {
        label: 'Limited Assistance',
        desc: 'Help on some occasions',
        input: ['Min Value'],
      },
      {
        label: 'Extensive Assistance',
        desc: 'Help throughout task, but performs 50% or more of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Maximal Assistance',
        desc: 'Help throughout task, but performs less than 50% of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Total Dependence',
        desc: 'Full performance by others during entire period',
        input: ['Min Value'],
      },
      {
        label: 'Activity did not occur',
        desc: 'During entire period [DO NOT USE THIS CODE IN SCORING CAPACITY]',
        input: ['Min Value'],
      },
    ],
  },

  {
    configLabel: 'Dressing lower body',
    configValue: 'lower_body',
    configDesc:
      'How medications are managed (e.g., remembering to take medicines, opening bottles, taking correct drug dosages, giving injections,applying ointments)',
    char: 'D',
    steps: [
      {
        label: 'Independent',
        desc: 'No help, setup, or supervision',
        input: ['Min Value'],
      },
      {
        label: 'Setup help only',
        desc: '',
        input: ['Min Value'],
      },
      {
        label: 'Supervision',
        desc: 'Oversight / Cuing',
        input: ['Min Value'],
      },
      {
        label: 'Limited Assistance',
        desc: 'Help on some occasions',
        input: ['Min Value'],
      },
      {
        label: 'Extensive Assistance',
        desc: 'Help throughout task, but performs 50% or more of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Maximal Assistance',
        desc: 'Help throughout task, but performs less than 50% of task on own',
        input: ['Min Value'],
      },
      {
        label: 'Total Dependence',
        desc: 'Full performance by others during entire period',
        input: ['Min Value'],
      },
      {
        label: 'Activity did not occur',
        desc: 'During entire period [DO NOT USE THIS CODE IN SCORING CAPACITY]',
        input: ['Min Value'],
      },
    ],
  },
];

export const currentDate = new Date();
