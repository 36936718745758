import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from '../api-service/api.service';
import { NAVIGATE_ROUTES } from '../../global/constant';

@Injectable({
  providedIn: 'root',
})
export class ErmTokenService {
  apiEndPoint = NAVIGATE_ROUTES;

  constructor(private apiService: ApiService) {}

  async getErmToken(data: any): Promise<Observable<any>> {
    let res: any = [];
    const url = `${this.apiEndPoint.GET_ERM_TOKEN}`;
    await this.apiService.postErmTokenGet(url, data).then((resData: any) => {
      if (resData) {
        res = resData;
      }
    });

    return of(res);
  }
}
