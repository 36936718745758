import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler/error-handler.service';
import { catchError, finalize, tap } from 'rxjs';
import { environment } from 'src/environments/environment.dev';
import { constants } from '../../global/constant';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  URL = environment; // endpoint URL
  constructor(
    private http: HttpClient,
    private router: Router,
    private httpErrorHandler: ErrorHandlerService,
    private toastr: ToastrService,
    private loader: NgxUiLoaderService
  ) {}

  async getHeaders(tokenRequired: any, formData?: any, isTokenChange?: any) {
    const Timeout = constants.TIMEOUT_TIME;
    const token: any = isTokenChange
      ? await localStorage.getItem('erm_access_token')
      : await localStorage.getItem('token');

    if (tokenRequired) {
      return new HttpHeaders().set('authorization', `Bearer ${token}`);
    } else if (formData) {
      if (token) {
        return new HttpHeaders()
          .set('authorization', `Bearer ${token}`)
          .set('timeout', `${Timeout}`);
      } else {
        return new HttpHeaders()
          .set('authorization', '')
          .set('timeout', `${Timeout}`);
      }
    } else {
      return new HttpHeaders().set('authorization', '');
    }
  }

  async get(
    path: any,
    isLoader?: any,
    isToast?: boolean,
    isLoaderStop = true,
    isTokenChange?: any
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      // NOSONAR
      try {
        if (isLoader) {
          this.loader.start();
        }

        const observer = {
          next: (res: any) => {
            // NOSONAR
            if (res && (res.responseMessage || res.msg)) {
              const message = res.responseMessage
                ? res.responseMessage
                : res.msg;
              if (isToast) {
                this.toastr.success(message);
              }
            }
            if (res.message && res.statusCode == 500) {
              this.toastr.error(res.message);
            }
            resolve(this.forbiddenAccess(res));
          },
          error: (err: any) => {
            reject(this.httpErrorHandler.handleError(path, err));
          },
          complete: () => {
            if (isLoaderStop) {
              this.loader.stop();
            }
          },
        };

        const networkIsConnected = this.getNetworkConnection();

        if (networkIsConnected) {
          isTokenChange;
          const token = localStorage.getItem('token');
          const headers = isTokenChange
            ? await this.getHeaders(token, false, isTokenChange)
            : await this.getHeaders(token, false);
          const request$ = this.http.get(`${path}`, { headers }).pipe(
            tap(observer.next),
            catchError((err) => {
              observer.error(err);
              throw err;
            }),
            finalize(observer.complete)
          );

          request$.subscribe();
        } else {
          this.loader.stop();
          this.showInternetError();
          reject(constants.NO_INTERNET_CONNECTION_MSG);
        }
      } catch (error) {
        reject(error); // In case there's any error during the try block
      }
    });
  }

  // this function should be used for post details without header token

  async post(
    path: any,
    obj: any,
    authorize: any,
    isLoader = true
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        if (isLoader) {
          this.loader.start();
        }

        const observer = {
          next: (res: any) => {
            // NOSONAR
            this.showIfSuccess(res);
            resolve(this.forbiddenAccess(res));
          },
          error: (err: any) => {
            this.loader.stop();
            this.toastr.error(err.error.error);
            reject(this.httpErrorHandler.handleError(path, err));
          },
          complete: () => {
            this.loader.stop();
          },
        };

        const networkIsConnected = this.getNetworkConnection();

        if (networkIsConnected) {
          let headers: any;
          // Assuming you have a method getHeaders similar to the previous example to retrieve the headers.
          headers = await this.getHeaders(authorize, false);
          // You need to set the proper content-type for your post request, assuming it's JSON here.
          // headers = headers.append('Content-Type', 'application/json');

          const request$ = this.http
            .post<any>(`${path}`, obj, { headers })
            .pipe(
              tap(observer.next),
              catchError((err) => {
                observer.error(err);
                throw err;
              }),
              finalize(observer.complete)
            );

          request$.subscribe();
        } else {
          this.loader.stop();
          this.showInternetError();
          reject(constants.NO_INTERNET_CONNECTION_MSG);
        }
      } catch (error) {
        reject(error); // In case there's any error during the try block
      }
    });
  }

  async patch(path: any, obj: any, authorize: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        this.loader.start();

        const patchobserver = {
          next: (res: any) => {
            // NOSONAR
            this.showIfSuccess(res);
            resolve(this.forbiddenAccess(res));
          },
          error: (err: any) => {
            reject(this.httpErrorHandler.handleError(path, err));
          },
          complete: () => {
            this.loader.stop();
          },
        };

        const networkIsConnected = await this.getNetworkConnection();

        if (networkIsConnected) {
          let headers: any;

          // Assuming you have a method getHeaders similar to the previous examples to retrieve the headers.
          headers = await this.getHeaders(authorize, false);

          // You need to set the proper content-type for your patch request, assuming it's JSON here.
          // headers = headers.append('Content-Type', 'application/json');

          const request$ = this.http
            .patch<any>(`${path}`, obj, { headers })
            .pipe(
              tap(patchobserver.next),
              catchError((err) => {
                patchobserver.error(err);
                throw err;
              }),
              finalize(patchobserver.complete)
            );

          request$.subscribe();
        } else {
          this.loader.stop();
          this.showInternetError();
          reject(constants.NO_INTERNET_CONNECTION_MSG);
        }
      } catch (error) {
        reject(error); // In case there's any error during the try block
      }
    });
  }

  async put(path: any, obj?: any, authorize?: any): Promise<any> {
    // NOSONAR
    return new Promise(async (resolve, reject) => {
      try {
        this.loader.start();

        const putobserver = {
          next: (res: any) => {
            // NOSONAR
            this.showIfSuccess(res);
            resolve(this.forbiddenAccess(res));
          },
          error: (err: any) => {
            this.loader.stop();
            reject(this.httpErrorHandler.handleError(path, err));
          },
          complete: () => {
            this.loader.stop();
          },
        };

        const networkIsConnected = await this.getNetworkConnection();

        if (networkIsConnected) {
          const token = localStorage.getItem('token');
          const headers = await this.getHeaders(token, false);

          const request$ = this.http.put<any>(`${path}`, obj, { headers }).pipe(
            tap(putobserver.next),
            catchError((err) => {
              putobserver.error(err);
              throw err;
            }),
            finalize(putobserver.complete)
          );

          request$.subscribe();
        } else {
          this.loader.stop();

          this.toastr.error(constants.NO_INTERNET_CONNECTION_MSG);
          this.toastr.warning(constants.WARNING_INTERNET_MSG);
          reject(constants.NO_INTERNET_CONNECTION_MSG);
        }
      } catch (error) {
        reject(error); // In case there's any error during the try block
      }
    });
  }

  async delete(
    path: any,
    authorize: any,
    isLoader = true,
    showToast = false
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        if (isLoader) {
          this.loader.start();
        }

        const deleteobserver = {
          next: (res: any) => {
            // NOSONAR
            if (res && (res.responseMessage || res.msg) && showToast) {
              const message = res.responseMessage
                ? res.responseMessage
                : res.msg;
              this.toastr.success(message);
            }
            this.loader.stop();
            resolve(this.forbiddenAccess(res));
          },
          error: (err: any) => {
            this.loader.stop();
            reject(this.httpErrorHandler.handleError(path, err));
          },
          complete: () => {
            this.loader.stop();
          },
        };

        const networkIsConnected = await this.getNetworkConnection();

        if (networkIsConnected) {
          let headers: any;
          const authorize = localStorage.getItem('token');
          headers = await this.getHeaders(authorize, false);

          const request$ = this.http.delete(`${path}`, { headers }).pipe(
            tap(deleteobserver.next),
            catchError((err) => {
              deleteobserver.error(err);
              throw err;
            }),
            finalize(deleteobserver.complete)
          );

          request$.subscribe();
        } else {
          this.loader.stop();
          this.showInternetError();
          reject(constants.NO_INTERNET_CONNECTION_MSG);
        }
      } catch (error) {
        reject(error); // In case there's any error during the try block
      }
    });
  }

  async postErmTokenGet(path: any, obj: any, isLoader = true): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        if (isLoader) {
          this.loader.start();
        }

        const observer = {
          next: (res: any) => {
            // NOSONAR
            this.showIfSuccess(res);
            resolve(this.forbiddenAccess(res));
          },
          error: (err: any) => {
            this.loader.stop();
            this.toastr.error(err.error.error);
            reject(this.httpErrorHandler.handleError(path, err));
          },
          complete: () => {
            this.loader.stop();
          },
        };

        const networkIsConnected = this.getNetworkConnection();

        if (networkIsConnected) {
          let headers: any;
          // Assuming you have a method getHeaders similar to the previous example to retrieve the headers.
          headers = await this.getHeaders(null, false);
          // You need to set the proper content-type for your post request, assuming it's JSON here.
          // headers = headers.append('Content-Type', 'application/json');

          const request$ = this.http
            .post<any>(`${path}`, obj, { headers })
            .pipe(
              tap(observer.next),
              catchError((err) => {
                observer.error(err);
                throw err;
              }),
              finalize(observer.complete)
            );

          request$.subscribe();
        } else {
          this.loader.stop();
          this.showInternetError();
          reject(constants.NO_INTERNET_CONNECTION_MSG);
        }
      } catch (error) {
        reject(error); // In case there's any error during the try block
      }
    });
  }

  getNetworkConnection() {
    const isOnline: boolean = navigator.onLine;
    if (isOnline) {
      return true;
    }
    return false;
  }

  forbiddenAccess(res: any) {
    return res;
  }

  showInternetError() {
    this.toastr.error(constants.NO_INTERNET_CONNECTION_MSG);
    this.toastr.warning(constants.WARNING_INTERNET_MSG);
  }

  showIfSuccess(res: any) {
    if (res && (res.responseMessage || res.msg)) {
      const message = res.responseMessage ? res.responseMessage : res.msg;
      this.toastr.success(message);
    }
    this.loader.stop();
  }
}
