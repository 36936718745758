import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ERM_TOKEN_INFO, currentDate } from 'src/app/shared/global/constant';
import { ErmTokenService } from 'src/app/shared/services/erm-token-service/erm-token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  admin_image_url = environment.admin_image_url;
  tokenInfo = ERM_TOKEN_INFO;
  date = currentDate;
  constructor(
    private router: Router,
    private ermTokenService: ErmTokenService
  ) {}
  navigateTo(directUrl: string) {
    this.router.navigate([directUrl]);
  }

  ngOnInit(): void {
    this.getToken();
  }

  async getToken() {
    const ermObjForm = new FormData();
    ermObjForm.append('grant_type', this.tokenInfo.grant_type);
    ermObjForm.append('client_id', this.tokenInfo.client_id);
    ermObjForm.append('scope', this.tokenInfo.scope);
    ermObjForm.append('user_role', this.tokenInfo.user_role);
    ermObjForm.append('username', this.tokenInfo.username);
    ermObjForm.append('password', this.tokenInfo.password);

    (await this.ermTokenService.getErmToken(ermObjForm)).subscribe(
      (res: any) => {
        localStorage.setItem('erm_access_token', res.access_token);
      }
    );
  }
}
