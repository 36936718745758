import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  public mobileNotverified = new BehaviorSubject<any>(null);
  public emailNotverified = new BehaviorSubject<any>(null);
  constructor(
    // private apiService : ApiService,
    private router: Router // private loginService: LoginService
  ) {}

  async handleError(
    _serviceName: any,
    error: HttpErrorResponse
  ): Promise<Observable<never>> {
    try {
      const errorMessage =
        error.error.error ||
        error.error.message ||
        error.error.responseMessage ||
        'Something went wrong';

      if (error.error.data && error.error.data.isMobileVerified === 0) {
        this.mobileNotverified.next(true);
        this.emailNotverified.next(false);
      } else if (error.error.data && error.error.data.isEmailVerified === 0) {
        this.mobileNotverified.next(false);
        this.emailNotverified.next(true);
      }

      // this.toast.error(errorMessage);
      switch (error.status) {
        case 400:
        case 403:
        case 404:
        case 500:
        case 501:
          return throwError(() => error);
        case 401:
          localStorage.clear();
          this.router.navigateByUrl('auth');
          // await this.apiService.put(NAVIGATE_ROUTES.LOGOUT).then((resData: any) => {
          //   let res = resData
          //   localStorage.clear();
          //   this.router.navigateByUrl('auth');
          // })

          // this.localStorageService.clearStorage();
          // await this.localStorageService.clearDataFromIndexedDB();
          // window.location.reload();
          return throwError(() => error);

        default:
          return throwError(() => new Error('Unknown error occurred'));
      }
    } catch (cerror) {
      return throwError(() => cerror);
    }
  }
}
