<div class="div">
  <div class="div-2">
    <div class="column-2">
      <div class="div-24">
        <div class="div-31">
          <div class="div-32"></div>
          <div class="div-33">
            <div class="div-34">Welcome, Dev Tiwari</div>
            <div class="div-35">{{date | date:'EEEE, dd MMMM yyyy'}}</div>
            <div class="div-36">
              <div class="div-37">
                <div class="column-3">
                  <div class="div-38">
                    <img loading="lazy" alt="" src="{{ admin_image_url }}Graph.png" class="img-14" />

                    <div class="div-39">Total Admin Users</div>
                    <div class="div-40">20</div>
                  </div>
                </div>
                <div class="column-4">
                  <div class="div-41">
                    <img loading="lazy" alt="" src="{{ admin_image_url }}Graph.png" class="img-15" />
                    <div class="div-42">Total Customers</div>
                    <div class="div-43">15</div>
                  </div>
                </div>
                <div class="column-5">
                  <div class="div-44">
                    <img loading="lazy" alt="" src="{{ admin_image_url }}Graph.png" class="img-16" />
                    <div class="div-45">Total Participants</div>
                    <div class="div-46">20</div>
                  </div>
                </div>
                <div class="column-6">
                  <div class="div-47">
                    <img loading="lazy" alt="" src="{{ admin_image_url }}Graph.png" class="img-17" />
                    <div class="div-48">Total Reports</div>
                    <div class="div-49">20</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="div-50">
              <div class="div-51">
                <div class="column-7">
                  <div class="div-52">
                    <div class="div-53">
                      <div class="div-54">Total Admin Users</div>
                      <img loading="lazy" alt="" src="{{ admin_image_url }}37.png" class="img-18" />
                    </div>
                    <div class="div-55">
                      <img loading="lazy" alt="" src="{{ admin_image_url }}chart.png" class="img-19" />
                      60%
                    </div>
                    <div class="div-57">
                      <div class="div-58">
                        <div class="div-59"></div>
                        <div class="div-60">
                          <span style="color: rgba(100, 116, 139, 1)">
                            Type of Rights:
                          </span>
                        </div>
                      </div>
                      <div class="div-61">
                        <div class="div-62"></div>
                        <div class="div-63">
                          <span style="font-weight: 400">Type of Rights</span>
                          <span style="color: rgba(100, 116, 139, 1)">:</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column-8">
                  <div class="div-64">
                    <div class="div-65">
                      <div class="div-66">Total Customers</div>
                      <img loading="lazy" alt="" src="{{ admin_image_url }}37.png" class="img-20" />
                    </div>
                    <div class="div-67">
                      <img loading="lazy" alt="" src="{{ admin_image_url }}chart.png" class="img-21" />
                      60%
                    </div>
                    <div class="div-69">
                      <div class="div-70">
                        <div class="div-71"></div>
                        <div class="div-72">
                          <span style="color: rgba(100, 116, 139, 1)">
                            Status:
                          </span>
                        </div>
                      </div>
                      <div class="div-73">
                        <div class="div-74"></div>
                        <div class="div-75">
                          <span style="color: rgba(100, 116, 139, 1)">
                            BBA 1:
                          </span>
                        </div>
                      </div>
                      <div class="div-76">
                        <div class="div-77"></div>
                        <div class="div-78">
                          <span style="color: rgba(100, 116, 139, 1)">
                            BBA 1:
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>